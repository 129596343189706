<template>
  <SelectPanelDraggable
    :modelValue="items"
    :group="{ name: draggableGroup, put: false, pull: 'clone' }"
    v-bind="$attrs"
  >
    <slot />
  </SelectPanelDraggable>
  <InfiniteScroll v-if="hasNextPage && !error" :fetchNextPage="requestNextPage" :threshold="500">
    <Skeleton width="100%" />
  </InfiniteScroll>
  <ErrorState v-if="error" :error="error" @retry="requestNextPage()" />
</template>

<script setup lang="ts">
import { ErrorState, InfiniteScroll, SelectPanelDraggable, Skeleton } from '@lasso/luikit'
import { AudienceCategoryInfo, AudienceInfo } from '@lasso/api-activation/activation'

import { draggableGroup } from '../consts'

const { items, error, hasNextPage, requestNextPage } = defineProps<{
  items: Array<AudienceInfo | AudienceCategoryInfo>
  error?: unknown
  hasNextPage: boolean
  requestNextPage: () => Promise<unknown>
}>()

defineOptions({
  inheritAttrs: false,
})
</script>
