<template>
  <Box class="item-info" data-test-id="selected-audience-item-info">
    <Typography variant="caption1" color="textSecondaryLight" uppercase>
      {{ label }}:
    </Typography>
    <Typography variant="caption1" color="textSecondaryLight">
      <slot />
    </Typography>
  </Box>
</template>

<script setup lang="ts">
import { Box, Typography } from '@lasso/luikit'

const { label } = defineProps<{
  label: string
}>()
</script>

<style scoped>
.item-info {
  @apply flex items-center gap-2 pr-4;
}

.item-info + .item-info {
  @apply pl-4 border-l;
}
</style>
